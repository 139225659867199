.contact {
    display: flex;
    justify-content: center;

    margin-top: 100px;

    color: var(--color-text);
    transition: color .4s;
}

.contact--card {
    max-width: 800px;

    padding: 20px;

    font-size: .8em;
}

.contact--handlecontainer {
    display: flex;
    align-items: center;
}

.contact--link {
    color: var(--color-link);
}


.contact--handlecontainer > p {
    margin-left: 10px;
}