.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    width: 100%;
    height: 100vh;
    background-color: var(--color-background);
    color: var(--color-text);

    transition: background-color .4s;
}

@media screen and (min-width: 1000px) {
    .home {
        flex-direction: row;
        padding: 20px;
    }
}

.home--rightpane {
    margin: auto;
}

.home--bio {
    padding: 30px;
}

.home--rightpane > img {
    width: 400px;
    height: 400px;
}