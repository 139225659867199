.projects {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 70px;
}

.projects--card {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 20px;
    padding: 20px;

    background-color: var(--color-cardbg);
    color: var(--color-text);

    border-radius: 20px;

    cursor: pointer;

    width: 100%;
    transition: background-color .4s;
}

.projects--card:hover {
    background-color: var(--color-hover);
}

@media screen and (min-width: 820px) {
    .projects--card {
        width: 800px;
        border-radius: 100px;
    }
}

.projects--title {
    margin-left: 25px;

    font-size: .7rem;
}

.projects--list {
    font-size: .5em;
}

.projects--image {
    margin-right: 10px;
    
    width: 100px;
}

.projects--info {
    margin-right: 10px;
}