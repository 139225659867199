.navbar {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    width: 100vw;
    height: 72px;
    z-index: 98;

    padding: 10px;

    /* background-color: var(--color-background);
    transition: background-color .4s; */
}

.navbar--button {
    padding: 15px 20px;
    
    border-radius: 50px;
    border: none;
    background-color: var(--color-background);

    font-family: "Varela";
    font-size: 16px;
    color: var(--color-text);
    
    transition-property: color, background-color;
    transition-duration: .4s;
}

.navbar--button:hover, .navbar--focused {
    background-color: var(--color-between);
    color: white;
    cursor: pointer;
}