@font-face {
    font-family: 'Varela';
    src: local("VarelaRound-Regular"), url("./fonts/VarelaRound-Regular.ttf") format("truetype");
}

html, body{
    font-family: "Varela";
    font-weight: 400;
    font-style: normal;
    font-size: 32px;

    margin: 0px;
    padding: 0px;
    background-color: var(--color-background);
}

body {
    transition: background-color .4s;
}

* {
    box-sizing: border-box;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 100;
    }
}
.fadein {
    animation: fade-in 1s;
}

@keyframes slide {
    from {
        transform: translateX(-25%);
        opacity: 0;
    }
    to {
        transform: translateX(0%);
        opacity: 100;
    }
}
.slide {
    animation: slide .5s;
}