.resume {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 70px;

    height: 99vh;
}

.resume > object {
    width: 100vw;
    max-width: 800px;
    height: 100%;
    background-color: white;
}

.resume > button {
    width: 200px;
    height: 30px;

    margin: 10px;

    border-radius: 100px;
    border: none;

    background-color: var(--color-cardbg);
    color: var(--color-text);

    transition: background-color .4s;

    cursor: pointer;
}

.resume > button:hover {
    background-color: rgb(224, 224, 224);
}